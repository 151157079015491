import { InputOnChangeData, Checkbox, CheckboxOnChangeData, } from '@fluentui/react-components';
import { DynamicFormComponentProps } from './dynamic-form-component';

export const DynamicFormCheckbox = (props: DynamicFormComponentProps) => {

    const onChange = (event: any, data: CheckboxOnChangeData): any => {
        props.updateQuestion(props.question.id, data.checked.toString(), props.sourceQuestionId, props.rowVirtualId);
    }
    
    const size = props.size == "small" ? "medium" : props.size ?? "medium";
    let value = props.question.answer == "true" ? true : false;

    return (
        <Checkbox key={props.question.key} size={size} style={{ width: "100%" }} onChange={onChange} disabled={props.question.readOnly} defaultChecked={value}/>
    );
}
