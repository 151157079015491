import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, } from '@fluentui/react-components';
import { ControlType, Question, ValidationMessage } from "../../TemplateData";
import { OptionOnSelectData } from '@fluentui/react-combobox';
import { Link } from 'react-router-dom';

type DynamicFormValidationMessages = {
    validationMessages?: Array<ValidationMessage>;
};

export const DynamicFormValidationMessages = (props: DynamicFormValidationMessages) => {

    let isVisible: boolean = props.validationMessages != undefined && props.validationMessages?.length > 0;

    if (isVisible)
        return (<>
            <div className="bg-red-300 px-4 py-3 shadow-lg mb-5 antialiased" role="alert">
                <div className="flex">
                    <div className="py-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 mr-4">
                            <path className="text-red-800" stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </div>
                    <div>
                        <p className="font-bold text-red-800 text-base">There were some errors with your submission</p>
                        <p className="text-sm text-red-900 mt-3">
                            <ul className="list-disc">
                                {props.validationMessages?.map((ValidationMessage, index) => (
                                    // <li key={"vm_" + index}>
                                    //     <Link to={"#test_test_test"}>{ValidationMessage.message}</Link>
                                    // </li>
                                    <li key={"vm_" + index}>
                                        {ValidationMessage.message}
                                    </li>
                                    ))}
                            </ul>
                        </p>
                    </div>
                </div>
            </div>            
        </>
        );
    else
        return (
            <></>
        );
}