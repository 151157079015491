import { Button, Col, Container, Form, Placeholder, Row } from "react-bootstrap";
import css from "./ContactDetails.module.css"
import { Link } from "react-router-dom";

export const PaymentOptions = () => {
    return (<>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
    <link
      href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&amp;family=Open+Sans:wght@300;400;700;800&amp;display=swap"
      rel="stylesheet"></link>
            <Container style={{ textAlign: "left" }}>
                <div className={css.title}>Payment Options</div>
                <Form className={css.form}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="iban">
                                <Form.Label>IBAN</Form.Label>
                                <Form.Control type="text" placeholder="IBAN" size="lg" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="iban-submitted-on">
                                <Form.Label>IBAN Submitted On</Form.Label>
                                <Form.Control type="text" placeholder="IBAN Submitted On" size="lg" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="photo-id">
                                <Form.Label>Photo ID Received</Form.Label>
                                <Form.Control type="text" placeholder="Photo ID Received" size="lg" disabled={true}/>
                                <Form.Control type="file" accept="image/*" aria-describedby="photoIdHelp" ></Form.Control>
                                <Form.Text id="photoIdHelp">Acceptable files JPG with size up to 2 MB.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="selfie-id">
                                <Form.Label>Selfie with ID Received</Form.Label>
                                <Form.Control type="text" placeholder="Selfie with ID Received" size="lg" disabled={true}/>
                                <Form.Control type="file" accept="image/*" ></Form.Control>
                                <Form.Text id="photoIdHelp">Acceptable files JPG with size up to 2 MB.</Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="utility-bill">
                                <Form.Label>Utility Bill Received</Form.Label>
                                <Form.Control type="text" placeholder="Utility Bill Received" size="lg" disabled={true} />
                                <Form.Control type="file" accept="image/*" ></Form.Control>
                                <Form.Text id="photoIdHelp">Acceptable files JPG with size up to 2 MB.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Link to="/" className="btn btn-secondary btn-lg">Back</Link>
                    &nbsp;
                    <Link to="/" className="btn btn-danger btn-lg">Submit</Link>
                    <div>
                        &nbsp;
                    </div>
                </Form>
            </Container>
            <div style={{minHeight: "100px"}}>
                &nbsp;
            </div>
            </>
    );
}
