import { Link } from "react-router-dom";
import { ClaimHistory } from "./ClaimHistoryDetails";

export const ClaimHistoryPage = () => {
    return (
        <>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
    <link
      href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&amp;family=Open+Sans:wght@300;400;700;800&amp;display=swap"
      rel="stylesheet"></link>
            <ClaimHistory></ClaimHistory>
            <div>
                <Link to="/" className="btn btn-secondary btn-lg">Back</Link>
            </div>
            <br />
            <div style={{minHeight: 270}}>&nbsp;</div>
        </>
    );
}