import { Link } from "react-router-dom";
import { Button, Card, Field, Input } from "@fluentui/react-components";
import { CalendarMonthRegular, ArrowRightFilled, ArrowRightRegular, AttachArrowRightRegular, ArrowCircleRightFilled, ArrowCircleRightRegular } from "@fluentui/react-icons";
import { useHistory } from 'react-router-dom';

export const LoginPage = () => {
    const navigate = useHistory();

    const login = () => {
        navigate.push("/");
    }

    return (
        <section className="my-20 flex flex-col items-center ">
            <div className="w-1/3 font-seans shadow-xl my-20 rounded-md border border-gray-400 bg-gradient-to-r from-gray-100 to-gray-200">
                {/* <div className="m-6 text-center">
                <img className="h-15 w-60" src="https://www.irishtaxrebates.ie/img/itr-logo.svg" alt="Irish Tax Rebates" />
                </div> */}
                <h1 className="font-semibold text-2xl antialiased px-5 py-5 text-center text-red-800">Sign in to your account</h1>
                <div>
                    <Field label="Your email" className="mx-6 my-2">
                        <Input size="large" className="my-2" />
                    </Field>
                    <Field label="Password" className="mx-6 my-2">
                        <Input type="password" size="large" className="my-2"></Input>
                    </Field>

                </div>
                <div className="mx-6 my-6 text-right"><Button appearance="transparent">Forgot password?</Button></div>
                <div className="mx-6 my-6 text-center">
                    <Button appearance="primary" size="large" icon={<ArrowRightFilled className="w-2/3" />} iconPosition="after" onClick={login} >Sign in</Button>
                </div>
                <div className="mx-6 my-6 text-xs italic">
                    For information on how we use your data please see our <a>Privacy Policy</a>.
                </div>
            </div>
        </section>
    );
}