import React, { Component, ReactNode } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { PageHeader } from './layout/PageHeader';
import { PageFooter } from './layout/PageFooter';

type Props = {
  children: ReactNode;
}

export class Layout extends Component<Props> {
  static displayName = Layout.name;

  render() {
    return (
      <div className="app">
        <PageHeader />
        <Container className="mx-auto">
          {this.props.children}
        </Container>
        <PageFooter />
      </div>
    );
  }
}
