import { Link } from "react-router-dom";
import { Button, Card, Field, Input } from "@fluentui/react-components";
import { CalendarMonthRegular, ArrowRightFilled, PasswordRegular, AttachArrowRightRegular, ArrowCircleRightFilled, ArrowCircleRightRegular } from "@fluentui/react-icons";

export const ForgotPasswordPage = () => {
    return (
        <section className="my-20 flex flex-col items-center ">
            <div className="w-3/5 font-seans shadow-xl my-20 rounded-md border border-red-800">
                <h1 className="font-semibold text-2xl antialiased px-5 py-5 text-center text-red-800">Forgot Password</h1>
                <div>
                    <Field label="Enter your email address to request a password reset." className="mx-6 my-2">
                        <Input size="large" className="my-2" />
                    </Field>
                </div>
                <div className="mx-6 my-6 text-right">
                    <Button appearance="primary" size="large" icon={<PasswordRegular />} iconPosition="after" >Send</Button></div>
            </div>
        </section>
    );
}

