import * as React from "react";
import { Link } from "react-router-dom";

type MenuTileDetails = {
    header: string,
    description: string,
    links: Array<MenuTileLink>,
}

type MenuTileLink = {
    name: string,
    url: string,
}

export const MenuTile = (props: MenuTileDetails) => {
    return (
        <div className="max-w-sm bg-white border border-gray-300 rounded-lg shadow-lg my-10">
            <div className="align-middle text-center p-4 text bg-[#d52114] text-white font-semibold text-xl rounded-t-lg">
                {props.header}
            </div>
            <hr></hr>
            <div className="p-5 h-48">
                <p className="mb-3 font-normal text-gray-700"> {props.description}</p>
            </div>
            {props.links.map((link) => (
                <div >
                    <hr></hr>
                    <div className="align-middle text-center p-3">
                        <Link to={link.url} className="text-black font-semibold hover:underline" >{link.name}</Link>                        
                    </div>
                </div>
            ))}
        </div>
    );
}