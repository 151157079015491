import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

export const ClaimHistory = () => {
    let title = "My Claim History";
    let tableData = [{
        submittedOn: "28/08/2023 10:49",
        history: "Pending Information From You",
    }, {
        submittedOn: "25/07/2023 10:44",
        history: "Cancelled",
    }, {
        submittedOn: "25/07/2023 09:44",
        history: "Pending Information From You",
    }];

    return (
        <Container>
            <h2>{title}</h2>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Submitted On</th>
                        <th>Claim History</th>
                    </tr>
                </thead>
                <tbody> 
                    {tableData.map((row) => (
                        <tr>
                            <td>{row.submittedOn}</td>
                            <td>{row.history}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}