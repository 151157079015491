import { DataRow, FormLogic, PortalTable, Question, Template } from "../../../TemplateData";
import TableHelpers from "../../helpers/table-helpers";

export class TuitionCosts {
    template: Template;
    constructor(formTemplate: Template) {
        this.template = formTemplate;
    }

    registerLogic() {

        let table47: PortalTable | undefined = this.template?.tables?.find((table: PortalTable) => {
            return table.sourceQuestionId == "e975d8cf-c774-ee11-8179-002248a1f28f";
        });

        if (table47 != undefined) {
            table47.addButtonLabel = "Add another Year";
            table47.isAddVisible = false;
            table47.isRemoveVisible = false;

            const startYear = new Date().getFullYear();

            if (table47.rows != undefined && table47.rows?.length > 0) {
                let row = table47.rows[0];
                this.logicCreator(row, startYear);
            }

            for (let i = startYear - 1; i > startYear - 5; i--) {
                let row: DataRow | undefined = TableHelpers.addRow(table47);
                if (row != undefined)
                    this.logicCreator(row, i);
            }
        }
    }

    private logicCreator(row: DataRow, startYear: number) {

        if (this.template?.logics != undefined) {

            const yearColumnIndex: number = 1;
            const firstCurrencyColumnIndex: number = 2;
            let enabledYear = (new Date().getFullYear() - 1) == startYear;

            if (row.columns != undefined) {
                row.columns?.forEach((column: Question, index: number) => {
                    if (row.columns != undefined && index != 0) {
                        column.readOnly = !enabledYear;
                    }
                });

                row.columns[0].readOnly = enabledYear;
                if (enabledYear)
                    row.columns[0].answer = "true";

                row.columns[yearColumnIndex].readOnly = true;
                row.columns[yearColumnIndex].answer = startYear.toString();

                row.columns[firstCurrencyColumnIndex].mandatory = enabledYear;

                // let rowLogic: FormLogic = {
                //     sourceQuestions: [row.columns[firstCurrencyColumnIndex], row.columns[firstCurrencyColumnIndex + 1], row.columns[firstCurrencyColumnIndex + 2]],
                //     destinationQuestion: [row.columns[firstCurrencyColumnIndex + 3]],
                //     logic: this.totalFielCalculation,
                // };
                // this.template?.logics.push(rowLogic);

                let checkboxLogic: FormLogic = {
                    sourceQuestions: [row.columns[0]],
                    destinationQuestion: [row.columns[firstCurrencyColumnIndex]],
                    logic: this.enableRow,
                }
                this.template?.logics.push(checkboxLogic);
            }
        }
    }

    private totalFielCalculation(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && destination != undefined && destination.length > 0) {
            let emptyAnswers: Array<Question> = questions.filter((question: Question) => { return question.answer == undefined || question.answer == null || question.answer == "" });
            if (emptyAnswers.length == questions.length) {
                destination[0].answer = "";
                return;
            }

            let total: number = 0;
            questions.forEach((question) => {
                if (question.answer != undefined && question.answer != null && question.answer != "") {
                    total += parseFloat(question?.answer?.replace(/,/g, '').replace("€", ""));
                }
            });
            destination[0].answer = total.toString();
        }
    }

    private enableRow(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && questions.length > 0) {
            let checkbox = questions[0];

            destination?.forEach((question) => {
                question.mandatory = checkbox.answer == "true";
                question.readOnly = checkbox.answer != "true";
                if (checkbox.answer != "true")
                    question.answer = "";
            });
        }
    }

}