import React from 'react';
import { Page, Question, ControlType, ValidationMessage, PortalTable } from '../../TemplateData';
import parse from 'html-react-parser';
/*import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown';*/
// import { DatePicker } from '@fluentui/react-datepicker-compat';
/*import { TextField } from '@fluentui/react/lib/TextField';*/
import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, } from '@fluentui/react-components';
import { DynamicFormDropdown } from './dynamic-form-dropdown';
import { DynamicFormDatePicker } from './dynamic-form-datepicker';
import { DynamicFormInput } from './dynamic-form-input';
import { DynamicFormTextArea } from './dynamic-form-textarea';
import { DynamicFormNumber } from './dynamic-form-number';
import { DynamicFormMultiDropdown } from './dynamic-form-multidropdown';
import { DynamicFormValidationMessages } from './dynamic-form-validation-messages';
import { DynamicFormTable } from './dynamic-form-table';
import { DynamicFormCurrency } from './dynamic-form-currency';

type DynamicFormContentProps = {
    pages: Array<Page>;
    tables?: Array<PortalTable>;
    validationMessages?: Array<ValidationMessage>;
    currentPage: number;
    updateQuestion(questionId: string, answer: string): void;
};


export const DynamicFormContent = (props: DynamicFormContentProps) => {
    if (props.pages.length == 0)
        return (<></>);

    const page = props.pages[props.currentPage];

    const internalParse = (text: string) => {
        if (text == null)
            return "";
        return parse(text);
    };

    const renderControl = (question: Question) => {
        var id = question.key;
        switch (question.questionType) {
            case ControlType.Text:
                return (
                    <DynamicFormInput key={"tb_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />
                );
            case ControlType.DropdownYesNo:
            case ControlType.Dropdown:
                return (
                    <DynamicFormDropdown key={"dd_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />
                );
            case ControlType.MultiselectDropdown:
                return (
                    <DynamicFormMultiDropdown key={"md_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />
                );
            case ControlType.Date:
                return (<DynamicFormDatePicker key={"dt_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />)
            case ControlType.Decimal:
            case ControlType.WholeNumber:
                return (<DynamicFormNumber key={"n_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />);
            case ControlType.Money:
                return (<DynamicFormCurrency key={"n_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />);
            case ControlType.MultilineText:
                return (<DynamicFormTextArea key={"ta_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} />);
            case ControlType.Table:
                let table: PortalTable | undefined = props.tables?.find((table: PortalTable) => { return table.sourceQuestionId == question.id; });
                if (table != undefined)
                    return (<DynamicFormTable key={"table_" + id} id={question.id} question={question} table={table} updateQuestion={props.updateQuestion} />);
        }
    };

    return (<div className="p-6 bg-gradient-to-r from-red-100 to-red-200">

        <DynamicFormValidationMessages validationMessages={props.validationMessages} ></DynamicFormValidationMessages>

        <div>
            {internalParse(page.header.headerHtml)}
        </div>
        {page.sections.map((section) => (
            <div className="" key={"section_" + section.key}>
                <div>
                    {internalParse(section.header.headerHtml)}
                </div>
                <div>
                    {section.questions.map((question) => (
                        <div key={"question_" + question.key}>
                            {question.isHidden() == false &&
                                <div className="my-5" >
                                    <div>
                                        {internalParse(question.header.headerHtml)}
                                    </div>
                                    <div className="text-lg font-medium">
                                        {internalParse(question.questionHtml)}
                                        {question.mandatory &&
                                            <span className="text-red-700/100 pl-1">*</span>
                                        }
                                        <div className="px-2 pt-2">
                                            <Field validationState={question.validationState}>
                                                {renderControl(question)}
                                            </Field>
                                        </div>
                                        <div style={{ color: "red" }}>{question.questionType != ControlType.Text && question.questionType != ControlType.Dropdown && question.questionType != ControlType.DropdownYesNo && question.questionType != ControlType.Date && question.questionType != ControlType.MultiselectDropdown && question.questionType != ControlType.Money && question.questionType != ControlType.MultilineText && question.questionType != ControlType.Table ? question.questionType : ""}</div>
                                    </div>
                                    <div className="px-2 text-sm font-thin">
                                        {internalParse(question.footer.footerHtml)}
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
                <div>
                    {internalParse(section.footer.footerHtml)}
                </div>
            </div>
        ))}
        <div>
            {internalParse(page.footer.footerHtml)}
        </div>
    </div>);
}