import React, { Component } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import css from "./ContactDetails.module.css"

export const ContactDetails = () => {

    return (<>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" />
    <link
      href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&amp;family=Open+Sans:wght@300;400;700;800&amp;display=swap"
      rel="stylesheet"></link>
        <Container style={{ textAlign: "left" }}>
            <div className={css.title}>Contact Details</div>
            <Form className={css.form}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="First Name" size="lg" value="John" disabled={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Last Name" size="lg" value="Test" disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="dob">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control type="text" placeholder="Date of Birth" size="lg" value="01/01/1985" disabled={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Email" size="lg" value="john.test@gmail.com" disabled={true} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" placeholder="Phone" size="lg" value="085123333" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="marital-status">
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Select placeholder="Marital Status" size="lg">
                                <option>Marital Status</option>
                                <option value="1">Single</option>
                                <option value="2">Cohabiting</option>
                                <option value="3">Divorced</option>
                                <option value="1">Married</option>
                                <option value="2">Separated</option>
                                <option value="3">Single Parent</option>
                                <option value="1">Widowed</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="occupation">
                            <Form.Label>Occupation</Form.Label>
                            <Form.Control type="text" placeholder="Occupation" size="lg" value="IT Support" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-street-1">
                            <Form.Label>Street 1</Form.Label>
                            <Form.Control type="text" placeholder="Street 1" size="lg" value="St. Stephen’s Green" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-street-2">
                            <Form.Label>Street 2</Form.Label>
                            <Form.Control type="text" placeholder="Street 2" size="lg" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-street-3">
                            <Form.Label>Street 3</Form.Label>
                            <Form.Control type="text" placeholder="Street 3" size="lg" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-city">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" size="lg" value="Dublin" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-state">
                            <Form.Label>State/Province</Form.Label>
                            <Form.Control type="text" placeholder="State/Province" size="lg" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-postalcode">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" placeholder="Postal Code" size="lg" value="D02 DX88" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="address-country">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" size="lg" value="Ireland" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="spouse-name">
                            <Form.Label>Spouse Name</Form.Label>
                            <Form.Control type="text" placeholder="Spouse Name" size="lg" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="spouse-ppsn">
                            <Form.Label>Spouse PPSN Number</Form.Label>
                            <Form.Control type="text" placeholder="Spouse PPSN Number" size="lg" />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="occupation">
                            <Form.Label>Occupation</Form.Label>
                            <Form.Control type="text" placeholder="Occupation" size="lg" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="employer">
                            <Form.Label>Employer</Form.Label>
                            <Form.Control type="text" placeholder="Employer" size="lg" />
                        </Form.Group>
                    </Col>
                </Row>
                <hr></hr>
                <Link to="/" className="btn btn-secondary btn-lg">Back</Link>
                &nbsp;
                <Link to="/" className="btn btn-danger btn-lg">Submit</Link>
            </Form>
        </Container>    </>);
}