import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, InputOnChangeData, } from '@fluentui/react-components';
import { Question } from "../../TemplateData";
import { OptionOnSelectData } from '@fluentui/react-combobox';
import { DynamicFormComponentProps } from './dynamic-form-component';

// type DynamicFormInputProps = {
//     question: Question;
//     id: string;
//     updateQuestion(questionId: string, answer: string): void;
// };

export const DynamicFormInput = (props: DynamicFormComponentProps) => {

    const onChange = (event: any, data: InputOnChangeData): any => {
        props.updateQuestion(props.question.id, data.value ?? "", props.sourceQuestionId, props.rowVirtualId);
    }
    
    const size = props.size ?? "medium";     

    return (
        <Input key={props.question.key} size={size} style={{ width: "100%" }} onChange={onChange} disabled={props.question.readOnly} value={props.question.answer}/>
    );
}
