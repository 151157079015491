import React, { Component } from 'react';
import { MenuTile } from './MenuTile';

export const Home = () => {
    const menuItems = [{
        header: "My Personal Details",
        description: "Need to update your contact information, payment method or check the status of current or past claims?",
        links: [{
            name: "My Contact Details",
            url: "/contact-details"
        },
        {
            name: "My Payment Options",
            url: "/payment-options"
        },
        {
            name: "My Rebate History",
            url: "/rebate-history"
        },
        {
            name: "My Claim History",
            url: "/claim-history"
        },
        //{
        //    name: "My Pension Cover",
        //    url: "#"
        //},
        //{
        //    name: "My Insurance Cover",
        //    url: "#"
        //    }
        ]
    },
    {
        header: "My Tax Rebate Potential",
        description: "Need to update us with all the relevant details to maximise your rebate potential?",
        links: [{
            name: "My Tax Position",
            url: "/tax-position"
        }]
    },
    {
        header: "Got A Question?",
        description: "Looking for answer to questions on any aspects of your tax claim?",
        links: [{
            name: "My Tax Position",
            url: "#"
        },
        {
            name: "Contact Us",
            url: "#"
        }]
    }];

    return (<>
        <div>
            <h1 className="text-5xl leading-[10vh] font-semibold">Welcome, Graham</h1>
        </div>
        <div className='grid grid-cols-3 place-items-start'>
            {menuItems.map((item) => (
                <MenuTile header={item.header} description={item.description} links={item.links}></MenuTile>
            ))}
        </div>
    </>);
}
