import { DataRow, PortalTable, Question } from "../../TemplateData";
import FormHelpers from "./form-helpers";

export default class TableHelpers {
    static generateId(index: number | undefined): number {
        return Date.now() + Math.random();
    }

    static addRow(table: PortalTable): DataRow | undefined {
        let rows: number = table.rows?.filter((row: DataRow) => { return row.isDeleted == false; })?.length ?? 0;

        if (table.maxNumberOfRows == undefined || (table.maxNumberOfRows != undefined && rows < table.maxNumberOfRows)) {
            let dataRow: DataRow = {
                columns: table?.columns?.map((column: Question, index: number) => {
                    let copiedColumn: Question = { ...column };
                    copiedColumn.key = FormHelpers.generateId().toString() + "_" + index.toString();
                    return copiedColumn;
                }),
                virtualId: TableHelpers.generateId(table.rows?.length),
                isDeleted: false,
                key: FormHelpers.generateId().toString() + "_" + table.rows?.length.toString(),
            }
            table.rows?.push(dataRow);
            return dataRow;
        }
    }

    static findRowColumn(row: DataRow, columnId: string): Question | undefined {
        let column: Question | undefined = row.columns?.find((column: Question) => { return column.id == columnId });
        return column;
    }

    static findTableColumn(table: PortalTable, columnId: string, rowId: number): Question | undefined {
        let row: DataRow | undefined = table.rows?.find((row: DataRow) => { return row.virtualId == rowId });
        if (row != undefined) {
            let column: Question | undefined = TableHelpers.findRowColumn(row, columnId);
            return column;
        }
    }

    static setColumnAnswer(table: PortalTable, columnId: string, rowId: number, answer: string) {
        let column: Question | undefined = this.findTableColumn(table, columnId, rowId);
        if (column != undefined) {
            column.answer = answer;
        }
    }

    static removeRow(dataRow: DataRow) {
        dataRow.isDeleted = true;
    }

    // static removeRow(table: PortalTable, rowId: number)
    // {

    // }
}