import { SpinButton, SpinButtonOnChangeData, } from '@fluentui/react-components';
import { DynamicFormComponentProps } from './dynamic-form-component';

export const DynamicFormCurrency = (props: DynamicFormComponentProps) => {

    let displayValue: string | undefined | null;
    let value: number | undefined | null;
    let decimals: number = 2;

    const parser = (formattedValue: string | undefined | null): number => {
        if (formattedValue === null || formattedValue === undefined) {
            return NaN;
        }
        return parseFloat(formattedValue.replace(/,/g, '').replace("€", ""));
    }

    const formatter = (value: number): string => {
        return `€${value.toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };

    const valueConverter = (dataValue: number | undefined | null, dataDisplayValue: string | undefined | null) => {        
        if (dataValue !== undefined && dataValue !== null) {
            value = dataValue;
            displayValue = formatter(dataValue);
        } else if (dataDisplayValue !== undefined) {
            const newValue = parser(dataDisplayValue);
            if (!Number.isNaN(newValue)) {
                value = newValue;
                displayValue = formatter(newValue);
            } else {
                value = null;
                displayValue = "";
            }
        }
        else {
            value = null;
            displayValue = "";
        }
    }

    const onChange = (event: any, data: SpinButtonOnChangeData): any => {
        valueConverter(data.value, data.displayValue);
        props.updateQuestion(props.question.id, value?.toString() ?? "", props.sourceQuestionId, props.rowVirtualId);
    }

    valueConverter(undefined, props.question.answer);

    const size: "small" | "medium" = props.size == "large" ? "medium" : props.size ?? "medium";
    const minValue: number | undefined = props.question.minValue ?? undefined;
    const maxValue: number | undefined = props.question.maxValue ?? undefined;

    return (
        <SpinButton key={props.question.id} value={value} displayValue={displayValue?.toString()} size={size} style={{ width: "100%" }} onChange={onChange}
            disabled={props.question.readOnly} precision={2} min={minValue} max={maxValue} />
    );
}