import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { ControlType, ConversationLogic, ConversationLogicCondition, DataRow, FormLogic, Page, PageQuestion, PortalConversation, PortalTable, Question, Section, Template, ValidationMessage } from "../../TemplateData";
import { validMethods } from "workbox-routing/utils/constants";
import FormHelpers from "../helpers/form-helpers";
import TableHelpers from "../helpers/table-helpers";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import { table } from "console";
import { MedicalExpenses } from "./custom-logic/medical-expenses-table";
import { WorkFromHome } from "./custom-logic/work-from-home-table";
import { DependentRelatives } from "./custom-logic/dependent-relatives-table";
import { TuitionCosts } from "./custom-logic/tuition-costs-table";

export abstract class DynamicFormLogic {
    template: Template | null = null;

    getQuestionById(id: string): Question | null {

        let question: Question | null = null;
        this.template?.pages.forEach((page: Page) => {
            page?.sections.forEach((section: Section) => {
                var q = section?.questions.find((question: Question) => {
                    //console.log(`${question.id} ${id}`);
                    return question.id == id;
                });

                if (q != undefined) {
                    question = q;
                }
            })
        });

        return question;
    }

    addIsHiddenLogic(id: string, isHidden: () => boolean) {
        let question = this.getQuestionById(id);
        if (question != null) {
            question.isHidden = isHidden;
            //??
            if (isHidden()) {
                question.answer = undefined;
            }
        }
    }

    getPageQuestions(pageNumber: any): Array<Question> | null {
        let questions: Array<Question> = new Array<Question>();

        let page: Page | undefined = this.template?.pages[pageNumber];
        if (page != undefined) {
            page.sections.forEach((section: Section) => {
                section.questions.forEach((question: Question) => {
                    questions.push(question);
                });
            });
        }
        return questions;
    }

    getPageNumberForQuestion(id: string): number | undefined {
        let pageIndex: number = 0;
        let page: Page | undefined = this.template?.pages.find((page: Page, index: number) => {
            let section: Section | undefined = page.sections.find((section: Section) => {
                return section.questions.find((question: Question) => {
                    return question.id == id;
                }) != undefined;
            });

            if (section != undefined)
                pageIndex = index;
        });
        return pageIndex;
    }

    validatePage(template: Template, pageIndex: number): Array<ValidationMessage> | undefined {
        if (template?.questions == undefined)
            return undefined;

        let pageQuestions: Array<PageQuestion> = template?.questions.filter((question) => { return question.pageNumber == pageIndex });
        // pageQuestions.forEach((pageQuestion) => {
        //     switch(pageQuestion.question.questionType)
        //     {
        //         //temporary solution as I think that we should populate data on table 
        //         case ControlType.Table:

        //             break;
        //     }

        //     //if (pageQuestion.question.isHidden()) {
        //     //pageQuestion.question.answer = undefined;
        //     //}
        // });

        let errors: Array<ValidationMessage> | undefined = pageQuestions.filter((pageQuestion) => {
            let dataRow: Array<DataRow> | undefined;
            let isTable: boolean = false;
            switch (pageQuestion.question.questionType) {
                case ControlType.Table:
                    isTable = true;
                    if (!pageQuestion.question.isHidden()) {
                        let table: PortalTable | undefined = template?.tables?.find((table: PortalTable) => { return table.sourceQuestionId == pageQuestion.question.id });
                        if (table != undefined) {
                            //should consider shown only columns
                            dataRow = table.rows?.filter((dataRow: DataRow) => {
                                if (dataRow.isDeleted)
                                    return false;

                                let columnsWithError: Array<Question> | undefined = dataRow.columns?.filter((column: Question) => {
                                    var isEmpty = column.mandatory && (column.answer == null || column.answer == undefined || column.answer == "") && !column.isHidden();
                                    if (isEmpty)
                                        column.validationState = "error";
                                    else
                                        column.validationState = undefined;
                                    return isEmpty;
                                });
                                console.log(columnsWithError);
                                return columnsWithError != undefined && columnsWithError.length > 0;
                            });
                        }
                    }
                    break;
            }

            return pageQuestion.question.mandatory && (((pageQuestion.question.answer == undefined || pageQuestion.question.answer == "") && !pageQuestion.question.isHidden() && !isTable) ||
                isTable && dataRow != undefined && dataRow.length > 0
            );
        }).map((pageQuestion) => {
            //if (pageQuestion.question.mandatory && pageQuestion.question.answer == undefined) {
            let validation: ValidationMessage = {
                question: pageQuestion.question,
                message: `The question '${pageQuestion.question.questionHtml}' is mandatory.`,
            };
            validation.question.validationState = "error";
            return validation;
            //}
        });
        return errors;
    }

    abstract registerLogic(template: Template): void;
    abstract getNextPage(template: Template, currentPage: number): number;
}

export class TaxPositionLogic extends DynamicFormLogic {
    registerLogic(template: Template) {
        this.template = template;

        this.prepareConversation();
        //this.registerFormLogic();

        if (this.template != undefined) {
            this.template.logics = Array<FormLogic>();
         
            let wfhTable = new WorkFromHome(template);
            wfhTable.registerLogic();

            let meTable = new MedicalExpenses(template);
            meTable.registerLogic();

            let ddrTable = new DependentRelatives(template);
            ddrTable.registerLogic();

            let tuitionTable = new TuitionCosts(template);
            tuitionTable.registerLogic();
        }
    }

    getNextPage(template: Template, currentPage: any): number {
        this.template = template;

        let pageNumber: number = currentPage;
        let pageQuestions: Array<PageQuestion> | undefined = this.template.questions?.filter((question: PageQuestion) => { return question.pageNumber == currentPage && !question.question.isHidden(); });

        //console.log(pageQuestions);

        if (pageQuestions != undefined && pageQuestions.length > 0) {
            let lastVisibleQuestion: PageQuestion = pageQuestions[pageQuestions.length - 1];

            let questionLogic: Array<PortalConversation> | undefined = this.template?.conversation?.filter((conversation: PortalConversation) => {
                return conversation.sourceQuestionId == lastVisibleQuestion.question.id && this.questionLogic(lastVisibleQuestion.question.answer, conversation.answers);
            });

            //console.log(questionLogic);

            if (questionLogic != undefined && questionLogic.length > 0) {
                let nextQuestion: PageQuestion | undefined = this.template.questions?.find((question: PageQuestion) => { return questionLogic != undefined && question.question.id == questionLogic[0].nextQuestionId })

                //console.log(nextQuestion);

                if (nextQuestion != undefined)
                    pageNumber = nextQuestion.pageNumber;
            }
        }
        //console.log("p:" + pageNumber);
        return pageNumber;

        // let nextPageQuestion: Array<PageQuestion> | undefined = pageQuestions?.filter((question: PageQuestion) => {

        //     let questionLogic: Array<PortalConversation> | undefined = this.template?.conversation?.filter((conversation: PortalConversation) => {
        //         return conversation.sourceQuestionId == question.question.id;
        //     });

        //     if( questionLogic != undefined)
        //     {
        //         question.
        //     }
        // });



        // let page: Page | undefined = this.template?.pages[currentPage];
        // if (page != undefined) {
        //     let questions: Array<Question> | null = this.getPageQuestions(currentPage);
        //     let visibleQuestions: Array<Question> | undefined = questions?.filter((question) => { return !question.isHidden(); });
        //     let logics: Array<PortalConversation> = new Array<PortalConversation>();
        //     if (visibleQuestions != null) {
        //         visibleQuestions.filter((question: Question) => {
        //             let portalConversation: Array<PortalConversation> | undefined = this.template?.conversation?.filter((conversation: PortalConversation) => {
        //                 return conversation.sourceQuestionId == question.id;
        //             });

        //             if (portalConversation != undefined) {
        //                 portalConversation.forEach((conversation: PortalConversation) => {
        //                     logics.push(conversation);
        //                 });
        //             }
        //         });
        //     }

        //     console.log(logics);

        //     if (logics != undefined && visibleQuestions != undefined) {
        //         logics.forEach((conversation: PortalConversation) => {
        //             let question: Question | undefined = visibleQuestions?.find((question: Question) => {
        //                 return conversation.nextQuestionId != question.id;
        //             });
        //             if (question != undefined) {
        //                 let pageNumber: Number | undefined = this.getPageNumberForQuestion(conversation.nextQuestionId);
        //                 if (pageNumber != undefined && pageNumber != currentPage) {
        //                     return pageNumber;
        //                 }
        //             }
        //         });
        //     }
        // }
        // else {
        //     currentPage++;
        // }

        // return currentPage;
    }

    registerFormLogic() {
        if (this.template != undefined) {
            this.template.logics = Array<FormLogic>();

            var table41: PortalTable | undefined = this.template?.tables?.find((table: PortalTable) => {
                return table.sourceQuestionId == "7c5021a6-c674-ee11-8179-002248a1f28f";
            });
            console.log(table41);

            let table39: PortalTable | undefined = this.template?.tables?.find((table: PortalTable) => {
                return table.sourceQuestionId == "f7e95710-c674-ee11-8179-002248a1f28f";
            });

            if (table39 != undefined) {
                table39.addButtonLabel = "Add another Year";
                table39.isAddVisible = false;
                table39.isRemoveVisible = false;

                const startYear = new Date().getFullYear();

                if (table39.rows != undefined && table39.rows?.length > 0) {
                    let row = table39.rows[0];
                    this.table39_logicCreator(row, startYear);
                }

                for (let i = startYear - 1; i > startYear - 5; i--) {
                    let row: DataRow | undefined = TableHelpers.addRow(table39);
                    if (row != undefined)
                        this.table39_logicCreator(row, i);
                }
            }
        }
    }

    table39_logicCreator(row: DataRow, startYear: number) {
        if (this.template?.logics != undefined) {

            const yearColumnIndex: number = 1;
            const firstCurrencyColumnIndex: number = 3;
            let enabledYear = (new Date().getFullYear() - 1) == startYear;

            if (row.columns != undefined) {
                row.columns?.forEach((column: Question, index: number) => {
                    if (row.columns != undefined && index != 0 && index != row.columns?.length - 1) {
                        column.readOnly = !enabledYear;
                    }
                });

                row.columns[0].readOnly = enabledYear;
                if (enabledYear)
                    row.columns[0].answer = "true";

                row.columns[yearColumnIndex].readOnly = true;
                row.columns[yearColumnIndex].answer = startYear.toString();

                row.columns[firstCurrencyColumnIndex - 1].mandatory = enabledYear;
                row.columns[firstCurrencyColumnIndex + 3].mandatory = enabledYear;

                let rowLogic: FormLogic = {
                    sourceQuestions: [row.columns[firstCurrencyColumnIndex], row.columns[firstCurrencyColumnIndex + 1], row.columns[firstCurrencyColumnIndex + 2]],
                    destinationQuestion: [row.columns[firstCurrencyColumnIndex + 3]],
                    logic: this.table39_totalFielCalculation,
                };
                this.template?.logics.push(rowLogic);

                let checkboxLogic: FormLogic = {
                    sourceQuestions: [row.columns[0]],
                    destinationQuestion: [row.columns[firstCurrencyColumnIndex - 1], row.columns[firstCurrencyColumnIndex], row.columns[firstCurrencyColumnIndex + 1], row.columns[firstCurrencyColumnIndex + 2], row.columns[firstCurrencyColumnIndex + 3]],
                    logic: this.table39_enableRow,
                }
                this.template?.logics.push(checkboxLogic);
            }
        }
    }

    table39_totalFielCalculation(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && destination != undefined && destination.length > 0) {
            let emptyAnswers: Array<Question> = questions.filter((question: Question) => { return question.answer == undefined || question.answer == null || question.answer == "" });
            if (emptyAnswers.length == questions.length) {
                destination[0].answer = "";
                return;
            }

            let total: number = 0;
            questions.forEach((question) => {
                if (question.answer != undefined && question.answer != null && question.answer != "") {
                    total += parseFloat(question?.answer?.replace(/,/g, '').replace("€", ""));
                }
            });
            destination[0].answer = total.toString();
        }
    }

    table39_enableRow(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && questions.length > 0) {
            let checkbox = questions[0];

            destination?.forEach((question, index) => {
                //last is a total value, shouldn't be mandatory when readonly 
                if (index == destination.length - 1) {
                    question.mandatory = checkbox.answer == "true";
                    if (checkbox.answer != "true")
                        question.answer = "";
                }
                else {

                    if (checkbox.answer != "true")
                        question.answer = "";
                    question.readOnly = checkbox.answer != "true";

                    //first is a total numnber of days, when readonly shouldn't be required
                    if (index == 0)
                        question.mandatory = checkbox.answer == "true";

                }
            });
        }
    }
    // addTableRow(table: PortalTable): DataRow {
    //     let dataRow: DataRow = {
    //         columns: table?.columns?.map((column: Question, index: number) => {
    //             let copiedColumn: Question = { ...column };
    //             copiedColumn.key = FormHelpers.generateId().toString() + "_" + index.toString();
    //             return copiedColumn;
    //         }),
    //         //virtualId: generateId(),
    //         isDeleted: false,
    //         key: FormHelpers.generateId().toString() + "_" + table.rows?.length.toString(),
    //     }
    //     return dataRow;
    // }

    setHiddenLogicQuestion_002() {
        this.addIsHiddenLogic(QuestionId.Question_002, () => {
            let q = this.getQuestionById(QuestionId.Question_001);
            return q?.answer != OptionsQuestion_001.Single && q?.answer != OptionsQuestion_001.SingleParent;
        });
    }

    setHiddenLogicQuestion_003() {
        this.addIsHiddenLogic(QuestionId.Question_003, () => {
            let q = this.getQuestionById(QuestionId.Question_001);
            let q2 = this.getQuestionById(QuestionId.Question_002);
            let isVisible = (q?.answer == OptionsQuestion_001.Married || q?.answer == OptionsQuestion_001.CivilPartnership || q2?.answer == OptionsQuestion_002.Yes);
            return !isVisible;
        });
    }

    setHiddenLogicQuestion_004() {
        this.addIsHiddenLogic(QuestionId.Question_004, () => {
            let q = this.getQuestionById(QuestionId.Question_003);
            let q2 = this.getQuestionById(QuestionId.Question_002);
            let isVisible = (q?.answer == OptionsQuestion_001.Married || q?.answer == OptionsQuestion_001.CivilPartnership || q2?.answer == OptionsQuestion_002.Yes);
            return !isVisible;
        });
    }

    setHiddenLogicQuestion_028() {
        this.addIsHiddenLogic(QuestionId.Question_028, () => {
            let q = this.getQuestionById(QuestionId.Question_002);
            return q?.answer != OptionsQuestion_002.No;
        });
    }

    setHiddenLogicQuestion_067() {
        this.addIsHiddenLogic(QuestionId.Question_067, () => {
            let q = this.getQuestionById(QuestionId.Question_001);
            return q?.answer != OptionsQuestion_001.Separated && q?.answer != OptionsQuestion_001.Divorced;
        });
    }

    getConversationLogic(conversation: Array<PortalConversation>): Array<ConversationLogic> {
        let result: Array<ConversationLogic> = new Array<ConversationLogic>();
        for (var i = 0; i < conversation.length; i++) {
            var nextQuestionId: string = conversation[i].nextQuestionId;

            let existingLogic: ConversationLogic | undefined = result.find((r: ConversationLogic) => {
                return r.nextQuestionId == nextQuestionId;
            });

            if (existingLogic == undefined) {
                let logic: ConversationLogic = {
                    nextQuestionId: nextQuestionId,
                    conditions: [{ sourceQuestionId: conversation[i].sourceQuestionId, answers: conversation[i].answers }]
                };
                result.push(logic);
            }
            else {
                existingLogic.conditions?.push({ sourceQuestionId: conversation[i].sourceQuestionId, answers: conversation[i].answers });
            }
        }
        return result;
    }

    prepareConversation() {
        if (this.template?.conversation != undefined) {
            let logic = this.getConversationLogic(this.template?.conversation);

            // logic.forEach((l: ConversationLogic) => {
            //     if (l.conditions != undefined) {
            //         this.addIsHiddenLogic(l.nextQuestionId, () => {
            //             let isVisible: boolean = false;
            //             if (l.conditions != undefined) {
            //                 l.conditions.forEach((condition : ConversationLogicCondition) => {
            //                     let sourceQuestion = this.getQuestionById(condition.sourceQuestionId);
            //                     if (sourceQuestion?.answer != undefined) {
            //                         isVisible = condition.answers.indexOf(sourceQuestion?.answer) > -1;
            //                         if (isVisible)
            //                             break;
            //                     }
            //                 });
            //             }
            //             console.log(isVisible);
            //             return !isVisible;
            //         });
            //     }
            // });

            for (let i = 0; i < logic.length; i++) {
                let l: ConversationLogic = logic[i];

                if (l.conditions != undefined) {
                    this.addIsHiddenLogic(l.nextQuestionId, () => {
                        let isVisible: boolean = false;
                        if (l.conditions != undefined) {
                            for (let j = 0; j < l.conditions.length; j++) {
                                let sourceQuestion = this.getQuestionById(l.conditions[j].sourceQuestionId);
                                if (sourceQuestion?.answer != undefined) {

                                    isVisible = l.conditions[j].answers.indexOf(sourceQuestion?.answer) > -1;
                                    let t = this.getQuestionById(l.nextQuestionId);
                                    //console.log(`Conditions - ${sourceQuestion.questionHtml}, next q ${t?.questionHtml}`);
                                    //console.log(l.conditions[j].answers);

                                    if (l.conditions[j].answers.length == 1 && !isVisible) {
                                        switch (l.conditions[j].answers[0]) {
                                            case "NOT-NULL":
                                                //console.log("condition-not-null");
                                                //console.log(sourceQuestion?.answer);
                                                isVisible = sourceQuestion?.answer != null && sourceQuestion?.answer != undefined && sourceQuestion?.answer != "";
                                                break;
                                        }
                                    }

                                    if (isVisible)
                                        break;
                                }
                            }
                        }
                        return !isVisible;
                    });
                }
            }
        }
    }

    questionLogic(answer: string | undefined, conditionAnswers: Array<string>): boolean {
        let isVisible: boolean = false;
        if (answer != undefined && answer != null && conditionAnswers != undefined && conditionAnswers != null) {
            isVisible = conditionAnswers.indexOf(answer) > -1;

            //if a question is not visible but there is only 1 condition check additional conditions
            if (conditionAnswers.length == 1 && !isVisible) {
                switch (conditionAnswers[0]) {
                    case "NOT-NULL":
                        isVisible = answer != "";
                        break;
                }
            }
        }
        return isVisible;
    }
}

export enum QuestionId {
    //=CONCAT("Question_0",[@Question]," = ","""",[@[(Do Not Modify) Portal Question]],""", //",[@[Question on Portal]])
    Question_001 = "5a5195b9-8372-ee11-8178-000d3add60ec", //What is your current marital status?
    //Question_001 = "8f97f4c4-7275-ee11-8179-0022489b76eb", //Are you happy to give Irish Tax Rebates explicit permission to file a tax return on your behalf?
    Question_002 = "f45d4f86-fa73-ee11-8179-0022489b7248", //Are you living with a partner?
    Question_003 = "128349a6-fc73-ee11-8179-002248a1f28f", //Do you rent any property in Ireland in which you or a child of yours reside full time or part time?
    Question_004 = "d28f05c8-fc73-ee11-8179-002248a1f28f", //Is the lease registered with the Residential Tenancies Board?
    Question_005 = "685564ec-fc73-ee11-8179-002248a1f28f", //Are you in receipt of any housing assistance payments?
    Question_006 = "44df6211-fd73-ee11-8179-002248a1f28f", //Are you related to your landlord?
    Question_007 = "bdc0fa36-fd73-ee11-8179-002248a1f28f", //What date did you start renting the property?
    Question_008 = "df80ae4f-fd73-ee11-8179-002248a1f28f", //What date do you expect to stop renting the property?
    Question_009 = "85b8fd67-fd73-ee11-8179-002248a1f28f", //What is the address of the property being rented?
    Question_010 = "b1211982-fd73-ee11-8179-002248a1f28f", //What is the name of your landlord/letting agent to who you pay the rent?
    Question_011 = "990e7b98-fd73-ee11-8179-002248a1f28f", //How much rent do you pay each month?
    Question_012 = "3cf293da-fd73-ee11-8179-002248a1f28f", //What is your residential situation?
    Question_013 = "d19dd7ec-fd73-ee11-8179-002248a1f28f", //Has Your Spouse registered with us too?
    Question_014 = "aede1a0b-fe73-ee11-8179-002248a1f28f", //Please advise their name?
    Question_015 = "748d4024-fe73-ee11-8179-002248a1f28f", //Is your spouse living in Ireland?
    Question_016 = "2bca123d-fe73-ee11-8179-002248a1f28f", //Please advise their PPS number?
    Question_017 = "fb4be95b-fe73-ee11-8179-002248a1f28f", //Please confirm your date of Marriage/Civil Partnership?
    Question_018 = "97890669-fe73-ee11-8179-002248a1f28f", //Are you and your Spouse both working?
    Question_019 = "546ca27b-fe73-ee11-8179-002248a1f28f", //What is your Spouses Occupation?
    Question_020 = "44ed498f-fe73-ee11-8179-002248a1f28f", //Please confirm your date of Marriage/Civil Partnership?
    Question_021 = "943f3ced-1574-ee11-8179-0022489986c2", //Does your spouse have any income earned out side of Ireland since your year of marriage?
    Question_022 = "de274d05-1674-ee11-8179-0022489986c2", //Please advise their annual income in Euros?
    Question_023 = "5b127136-1674-ee11-8179-0022489986c2", //Do you have any children?
    Question_024 = "643a8172-1674-ee11-8179-0022489986c2", //Please advise your youngest childs name?
    Question_025 = "cad45fa8-1674-ee11-8179-0022489986c2", //Please advise your youngest childs PPS number?
    Question_026 = "c77e6303-1774-ee11-8179-0022489986c2", //Please advise your youngest childs date of birth?
    Question_027 = "12f54082-1774-ee11-8179-0022489986c2", //Do you have any children who are in any way incapacitated?
    Question_028 = "5f806b9c-1874-ee11-8179-0022489986c2", //Do You Have Any Children under the age of 18, or who were still in Full Time education in the past 4 years?
    Question_029 = "9e60ead8-1974-ee11-8179-0022489986c2", //Are you the Primary Carer for your child(ren)?
    Question_030 = "828bbe1a-1a74-ee11-8179-0022489986c2", //Does the mother/father of your child(ren) live with another partner?
    Question_031 = "c80392c8-bc74-ee11-8179-0022489986c2", //Does the mother/father of your child(ren) work full time?
    Question_032 = "aa3aadf2-bc74-ee11-8179-0022489986c2", //Would the mother/father of your child(ren) be willing to sign paperwork to allow you to get a tax credit if they were not using it?
    Question_033 = "28b69f3a-bd74-ee11-8179-0022489986c2", //Please provide the name of your eldest child?
    Question_034 = "f89e9152-bd74-ee11-8179-0022489986c2", //What is their Date of Birth?
    Question_035 = "bdbe6a76-bd74-ee11-8179-0022489986c2", //What is their PPS number?
    Question_036 = "028d3d7f-c574-ee11-8179-002248a1f28f", //Do you have any children who are in any way incapacitated which will mean that they cannot reasonably be expected to maintain themselves financially after the age of 18?
    Question_037 = "a46eb1d5-c574-ee11-8179-002248a1f28f", //Have you been required to work from home at any stage in the past 4 years?
    Question_038 = "f7e95710-c674-ee11-8179-002248a1f28f", //Has your employer paid you a tax free sum per day to cover your expenses?
    Question_039 = "93268635-c674-ee11-8179-002248a1f28f", //**Needs to show the WFH Table**
    Question_040 = "2c688481-c674-ee11-8179-002248a1f28f", //Have you incurred any medical expenses in the past 4 years?
    Question_041 = "7c5021a6-c674-ee11-8179-002248a1f28f", //**Needs to show Med Expenses Table**
    Question_042 = "05602f05-c774-ee11-8179-002248a1f28f", //Have paid for any tuition costs in the past 4 years?
    Question_043 = "95230137-c774-ee11-8179-002248a1f28f", //Please advise the name of the person who was attending college?
    Question_044 = "0b774d4a-c774-ee11-8179-002248a1f28f", //Please advise the name of the college?
    Question_045 = "8a2d2677-c774-ee11-8179-002248a1f28f", //Please advise the date that the course started?
    Question_046 = "9ddb4490-c774-ee11-8179-002248a1f28f", //Is the course Full Time or Part Time?
    Question_047 = "e975d8cf-c774-ee11-8179-002248a1f28f", //**Need to have a table for tax years 2019 - 2023 against tuition costs paid for 1 or 2 children in each year.**
    Question_048 = "3f29cf0e-c874-ee11-8179-002248a1f28f", //Are you making payments from your own bank account to a Pension policy?
    Question_049 = "60b5b02e-c874-ee11-8179-002248a1f28f", //Are you making payments from your own bank account to an Income Protection Policy?
    Question_050 = "85b78674-c874-ee11-8179-002248a1f28f", //Have you worked overseas in the past 4 years?
    Question_051 = "c6582368-e074-ee11-8179-0022489986c2", //**Need a table to tax years vs number of days worked abroad
    Question_052 = "efde6b92-e074-ee11-8179-0022489986c2", //Does your employer pay for all or some of your Medical Insurance?
    Question_053 = "847363bc-e074-ee11-8179-0022489986c2", //What years has the policy been in place?
    Question_054 = "f74b5cf8-e074-ee11-8179-0022489986c2", //Who is covered on the policy?
    Question_055 = "507e4216-e174-ee11-8179-0022489986c2", //How many adults are on the policy?
    Question_056 = "7136db41-e174-ee11-8179-0022489986c2", //How many children are covered on the policy?
    Question_057 = "ffd48987-e174-ee11-8179-0022489986c2", //Please provide the names and dates of birth for any children covered on the policy.
    Question_058 = "aca097a6-e174-ee11-8179-0022489986c2", //Have you been working in 2023 but are currently unemployed?
    Question_059 = "ceb447a9-e274-ee11-8179-0022489986c2", //What is your current situation?
    Question_060 = "7b989bc1-e274-ee11-8179-0022489986c2", //Are you currently in receipt of welfare benefits?
    Question_061 = "6da7821c-e374-ee11-8179-0022489986c2", //What date did you leave or are planning to leave Ireland?
    Question_062 = "8064f23a-e374-ee11-8179-0022489986c2", //What college are you attending?
    Question_063 = "5746da68-e474-ee11-8178-000d3add60ec", //Please advise the nature of the incapacity?
    Question_064 = "ce43db88-e474-ee11-8178-000d3add60ec", //Please advise the name of your child affected with the above condition?
    Question_065 = "7e98b7a4-e474-ee11-8178-000d3add60ec", //Please advise the PPS number of your child affected with the above condition?
    Question_066 = "814839be-e474-ee11-8178-000d3add60ec", //Please advise the date of birth of your child affected with the above condition?
    Question_067 = "af14f410-e574-ee11-8178-000d3add60ec", //Please advise the date of your Separation/Divorce?
    Question_068 = "eba30e72-e574-ee11-8178-000d3add60ec", //Are you now living with another partner?
    Question_069 = "9cba0993-e574-ee11-8178-000d3add60ec", //Are you making maintenance payments to your ex partner?
    Question_070 = "0c5fcea5-e574-ee11-8178-000d3add60ec", //Please confirm how much you pay each month?
    Question_071 = "d68a542d-7175-ee11-8178-000d3add60ec", //Please can you confirm the date that your Spouse passed away?
    Question_072 = "9706ac41-7175-ee11-8178-000d3add60ec", //Please confirm the name of your Spouse?
    Question_073 = "1adc6162-7175-ee11-8178-000d3add60ec", //Please confirm their PPS number?
    Question_074 = "12d65177-7175-ee11-8178-000d3add60ec", //Please confirm their date of birth?
    Question_075 = "56a540aa-7175-ee11-8178-000d3add60ec", //Are you currently living with another partner?
    Question_076 = "ab616ad7-7175-ee11-8178-000d3add60ec", //Are you financially supporting any elderly relatives?
    Question_077 = "336b3d1e-7275-ee11-8178-000d3add60ec", //
    Question_078 = "60f39705-7575-ee11-8178-000d3add60ec", //Have you paid a mortgage in 2022 and 2023?
    Question_079 = "c205ed44-7575-ee11-8178-000d3add60ec", //What was the outstanding balance on your mortgage on 31st December 2022?

}

export enum OptionsQuestion_001 {
    CivilPartnership = "961530001",
    Cohabiting = "961530007",
    Divorced = "961530004",
    Married = "961530000",
    Separated = "961530003",
    Single = "961530002",
    SingleParent = "961530006",
    Widowed = "961530005",
}

export enum OptionsQuestion_002 {
    Yes = "1",
    No = "0"
}