import { Link } from "react-router-dom";
import { ControlType, DataRow, PortalTable, Question } from "../../TemplateData";
import { DynamicFormInput } from "./dynamic-form-input";
import { DynamicFormDropdown } from "./dynamic-form-dropdown";
import { DynamicFormMultiDropdown } from "./dynamic-form-multidropdown";
import { DynamicFormDatePicker } from "./dynamic-form-datepicker";
import { DynamicFormNumber } from "./dynamic-form-number";
import { DynamicFormTextArea } from "./dynamic-form-textarea";
import { render } from "react-dom";
import { DynamicFormComponentProps } from "./dynamic-form-component";
import FormHelpers from "../helpers/form-helpers";
import { DynamicFormCurrency } from "./dynamic-form-currency";
import { Field } from "@fluentui/react-components";
import TableHelpers from "../helpers/table-helpers";
import { DynamicFormCheckbox } from "./dynamic-form-checkbox";

type DynamicFormTableProps = {
    question: Question;
    table: PortalTable;
    id: string;
    updateQuestion(questionId: string, answer: string, sourceQuestionId?: string, rowVirtualId?: number): void;
};

export const DynamicFormTable = (props: DynamicFormTableProps) => {
    // const generateId = (): number => {
    //     return Date.now() + Math.random();
    // }

    // const addRow = () => {
    //     let rows: number = props.table.rows?.filter((row) => { return row.isDeleted == false; })?.length ?? 0;
    //     if (props.table.maxNumberOfRows == undefined || (props.table.maxNumberOfRows != undefined && rows < props.table.maxNumberOfRows)) {
    //         let dataRow: DataRow = {
    //             columns: props.table?.columns?.map((column: Question, index: number) => {
    //                 let copiedColumn: Question = { ...column };
    //                 copiedColumn.key = FormHelpers.generateId().toString() + "_" + index.toString();
    //                 return copiedColumn;
    //                 // return { ...column };
    //             }),
    //             virtualId: generateId(),
    //             isDeleted: false,
    //             key: FormHelpers.generateId().toString() + "_" + props.table.rows?.length.toString(),
    //         }
    //         props.table.rows?.push(dataRow);
    //     }
    // };

    const addRow = () => {
        TableHelpers.addRow(props.table);
    };

    const removeRow = (row: DataRow) => {
        row.isDeleted = true;
        let rows: number = props.table.rows?.filter((row) => { return row.isDeleted == false; })?.length ?? 0;
        if ((props.table?.addFirstEmptyRow === true || props.table?.addFirstEmptyRow === undefined) && rows == 0) {
            addRow();
        }
    }

    const renderControl = (question: Question, rowVirtualId?: number) => {
        var id = question.key;
        switch (question.questionType) {
            case ControlType.Text:
                return (
                    <DynamicFormInput size="medium" key={"table_tb_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />
                );
            case ControlType.DropdownYesNo:
            case ControlType.Dropdown:
                return (
                    <DynamicFormDropdown size="medium" key={"table_dd_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />
                );
            case ControlType.MultiselectDropdown:
                return (
                    <DynamicFormMultiDropdown size="medium" key={"table_md_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />
                );
            case ControlType.Date:
                return (<DynamicFormDatePicker size="medium" key={"table_dt_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />)
            case ControlType.Decimal:
            case ControlType.WholeNumber:
                return (<DynamicFormNumber size="medium" key={"table_n_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />);
            case ControlType.Money:
                return (<DynamicFormCurrency size="medium" key={"table_n_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />);
            case ControlType.MultilineText:
                return (<DynamicFormTextArea size="medium" key={"table_ta_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />);
            case ControlType.Checkbox:
                return (<DynamicFormCheckbox size="medium" key={"table_ta_" + id} id={question.id} question={question} updateQuestion={props.updateQuestion} sourceQuestionId={props.question.id} rowVirtualId={rowVirtualId} />);
        }
    };

    const renderRow = (row: DataRow) => {
        if (row.isDeleted == false) {
            return (<tr key={"table_row_" + row.key} className="bg-white border-b border-red-200">
                {row?.columns?.map((column: Question, index: number) => (
                    <td key={"table_column_" + column.key} className="px-2 py-3">
                        <Field validationState={column.validationState}>{renderControl(column, row.virtualId)}</Field>
                    </td>
                ))}
                {renderActionColumn(row)}
            </tr>);
        }
    }

    const areEditActionsEnabled = (): boolean => {
        return props.table.isRemoveVisible ?? false;
    }

    const renderActionHeader = () => {
        if (areEditActionsEnabled()) {
            return (<td className="px-6 py-3"></td>);
        }
    }

    const renderActionColumn = (row: DataRow) => {
        if (areEditActionsEnabled()) {
            const removeButton = () => {
                if (props.table.isRemoveVisible)
                    return (<Link to="#" className="font-normal text-sm mx-1 bg-[#d52114] hover:bg-red-700 text-white py-2 px-2 rounded" onClick={() => removeRow(row)}>Delete</Link>)
            }

            return (<td>
                {removeButton()}
            </td>);
        }
    }

    const renderHeader = () => {
        let isHeaderVisible = false;

        isHeaderVisible = props.table.isAddVisible ?? false;
        let addButtonLabel: string = props.table.addButtonLabel ?? "Add";

        if (isHeaderVisible)
            return (<div className="text-sm mb-4 text-right">
                <Link to="#" className="font-normal mx-1 bg-[#d52114] hover:bg-red-700 text-white py-2 px-4 rounded" onClick={addRow}>
                    {addButtonLabel}</Link>
            </div>);
    };

    return (
        <>
            <div className="mb-4">
                {renderHeader()}
                <table className="font-normal text-sm w-full relative overflow-x-auto shadow-md">
                    <thead className="text-nirmal text-white bg-red-600">
                        <tr>
                            {props.table.columns?.map((column: Question) => (
                                <td className="px-2 py-3 font-medium" key={"table_column_" + column.key}>{column.questionHtml}</td>
                            ))}
                            {renderActionHeader()}
                        </tr>
                    </thead>
                    <tbody>
                        {props.table.rows?.map((row: DataRow, rowNumber: number) => (
                            renderRow(row)
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}