import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, } from '@fluentui/react-components';
import { ControlType, Question } from "../../TemplateData";
import { OptionOnSelectData } from '@fluentui/react-combobox';
import { DynamicFormComponentProps } from './dynamic-form-component';

// type DynamicFormDropdownProps = {
//     question: Question;
//     id: string;
//     sourceQuestionId?: string;
//     rowVirtualId?: number;
//     updateQuestion(questionId: string, answer: string, sourceQuestionId?: string, rowVirtualId?: number): void;
// };

export const DynamicFormDropdown = (props: DynamicFormComponentProps) => {

    const onChange = (event: any, data: OptionOnSelectData): any => {
        props.updateQuestion(props.question.id, data.optionValue ?? "", props.sourceQuestionId, props.rowVirtualId);
        //console.log(data);
    }

    // if (props?.question?.answer != undefined)
    //     console.log(props?.question?.answer);

    let option = props.question?.options?.find((option) => {
        return option.value == props?.question?.answer;
    });

    const size = props.size ?? "medium";

    return (
        <Dropdown key={props.question.id} size={size} style={{ minWidth: "80px", width: "100%" }} onOptionSelect={onChange} defaultValue={option?.name} defaultSelectedOptions={[option?.value ?? ""]}
            disabled={props.question.readOnly}>
            {props.question?.options?.map((option) => (
                <Option key={option.name + "_" + option.value} value={option.value} >
                    {option.name}
                </Option>
            ))}
        </Dropdown>
    );
}