import React, { Component } from 'react';
// import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';

import './custom.css'
import { ContactDetails } from './components/pages/ContactDetails';
import { TaxPosition } from './components/pages/TaxPosition';
import { PaymentOptions } from './components/pages/PaymentOptions';
import { RebateHistoryPage } from './components/pages/RebateHistory';
import { ClaimHistoryPage } from './components/pages/ClaimHistory';
import ScrollToTop from './components/ScrollToTop';
import { Route } from 'react-router';
import { LoginPage } from './components/pages/Login';
import { ForgotPasswordPage } from './components/pages/ForgotPassword';
import { ResetPasswordPage } from './components/pages/ResetPassword';



export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Layout>
        <ScrollToTop></ScrollToTop>
        {/* <Route exact path='/' component={Home} /> */}

        {/* <Route  path='/' element={Home} /> */}
        {/* {ApplicationPages.pages.map((page: AppPage) => (
          <Route path={page.path} element={page.element} />
        ))} */}

        <Route exact path='/' component={Home} />
        <Route path='/contact-details' component={ContactDetails} />
        <Route path='/tax-position' component={TaxPosition} />
        <Route path='/payment-options' component={PaymentOptions} />
        <Route path='/rebate-history' component={RebateHistoryPage} />
        <Route path='/claim-history' component={ClaimHistoryPage} />
        <Route path='/login' component={LoginPage} />
        <Route path='/forgot-password' component={ForgotPasswordPage} />
        <Route path='/reset-password' component={ResetPasswordPage} />
      </Layout>
    );
  }
}
