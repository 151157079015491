import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, InputOnChangeData, TextareaOnChangeData, } from '@fluentui/react-components';
import { Question } from "../../TemplateData";
import { DynamicFormComponentProps } from './dynamic-form-component';

// type DynamicFormTextAreaProps = {
//     question: Question;
//     id: string;
//     updateQuestion(questionId: string, answer: string): void;
// };

export const DynamicFormTextArea = (props: DynamicFormComponentProps) => {

    const onChange = (event: any, data: TextareaOnChangeData): any => {
        props.updateQuestion(props.question.id, data.value ?? "", props.sourceQuestionId, props.rowVirtualId);
    }

    return (
        <Textarea key={props.question.key} size="medium" style={{ width: "100%" }} onChange={onChange} value={props.question.answer ?? ""} disabled={props.question.readOnly} />
    );
}