import { DataRow, FormLogic, PortalTable, Question, Template } from "../../../TemplateData";
import TableHelpers from "../../helpers/table-helpers";

export class MedicalExpenses {
    template: Template;
    constructor(formTemplate: Template) {
        this.template = formTemplate;
    }

    registerLogic() {

        let table41: PortalTable | undefined = this.template?.tables?.find((table: PortalTable) => {
            return table.sourceQuestionId == "7c5021a6-c674-ee11-8179-002248a1f28f";
        });

        if (table41 != undefined) {
            table41.addButtonLabel = "Add another Year";
            table41.isAddVisible = false;
            table41.isRemoveVisible = false;

            const startYear = new Date().getFullYear();

            if (table41.rows != undefined && table41.rows?.length > 0) {
                let row = table41.rows[0];
                this.logicCreator(row, startYear);
            }

            for (let i = startYear - 1; i > startYear - 5; i--) {
                let row: DataRow | undefined = TableHelpers.addRow(table41);
                if (row != undefined)
                    this.logicCreator(row, i);
            }
        }
    }

    private logicCreator(row: DataRow, startYear: number) {
        
        if (this.template?.logics != undefined) {

            const yearColumnIndex: number = 1;
            const firstCurrencyColumnIndex: number = 2;
            let enabledYear = (new Date().getFullYear() - 1) == startYear;

            if (row.columns != undefined) {
                row.columns?.forEach((column: Question, index: number) => {
                    if (row.columns != undefined && index != 0 && index != row.columns?.length - 1) {
                        column.readOnly = !enabledYear;
                    }
                });

                row.columns[0].readOnly = enabledYear;
                if (enabledYear)
                    row.columns[0].answer = "true";

                row.columns[yearColumnIndex].readOnly = true;
                row.columns[yearColumnIndex].answer = startYear.toString();

                row.columns[firstCurrencyColumnIndex + 3].mandatory = enabledYear;

                let rowLogic: FormLogic = {
                    sourceQuestions: [row.columns[firstCurrencyColumnIndex], row.columns[firstCurrencyColumnIndex + 1], row.columns[firstCurrencyColumnIndex + 2]],
                    destinationQuestion: [row.columns[firstCurrencyColumnIndex + 3]],
                    logic: this.totalFielCalculation,
                };
                this.template?.logics.push(rowLogic);

                let checkboxLogic: FormLogic = {
                    sourceQuestions: [row.columns[0]],
                    destinationQuestion: [row.columns[firstCurrencyColumnIndex], row.columns[firstCurrencyColumnIndex + 1], row.columns[firstCurrencyColumnIndex + 2], row.columns[firstCurrencyColumnIndex + 3]],
                    logic: this.enableRow,
                }
                this.template?.logics.push(checkboxLogic);
            }
        }
    }

    private totalFielCalculation(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && destination != undefined && destination.length > 0) {
            let emptyAnswers: Array<Question> = questions.filter((question: Question) => { return question.answer == undefined || question.answer == null || question.answer == "" });
            if (emptyAnswers.length == questions.length) {
                destination[0].answer = "";
                return;
            }

            let total: number = 0;
            questions.forEach((question) => {
                if (question.answer != undefined && question.answer != null && question.answer != "") {
                    total += parseFloat(question?.answer?.replace(/,/g, '').replace("€", ""));
                }
            });
            destination[0].answer = total.toString();
        }
    }

    private enableRow(questions?: Array<Question>, destination?: Array<Question>) {
        if (questions != undefined && questions.length > 0) {
            let checkbox = questions[0];

            destination?.forEach((question, index) => {
                //last is a total value, shouldn't be mandatory when readonly 
                if (index == destination.length - 1) {
                    question.mandatory = checkbox.answer == "true";
                    if (checkbox.answer != "true")
                        question.answer = "";
                }
                else {

                    if (checkbox.answer != "true")
                        question.answer = "";
                    question.readOnly = checkbox.answer != "true";
                }
            });
        }
    }

}