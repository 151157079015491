export type Template = {
    header: HeaderDetails;
    footer?: TemplateFooter;
    pages: Array<Page>;
    conversation?: Array<PortalConversation>;
    currentPage: number,
    questions?: Array<PageQuestion>;
    pageHistory?: Array<number>;
    validationMessages?: Array<ValidationMessage>;
    tables?: Array<PortalTable>;
    logics?: Array<FormLogic>;
}

export type TemplateFooter = {
    isBackVisible?: boolean;
    isPrintVisible?: boolean;
    isSaveVisible?: boolean;
    isExportVisible?: boolean;
    isSubmitVisible?: boolean;
}

export type HeaderDetails = {
    headerHtml: string;
    //description: string;
}

export type FooterDetails = {
    footerHtml: string;
}

export type Page = {
    header: HeaderDetails;
    sections: Array<Section>;
    footer: FooterDetails;
    key: string;
    pageType: PageType;
}

export type Section = {
    header: HeaderDetails;
    questions: Array<Question>;
    footer: FooterDetails;
    key: string;
}

export type DropdownOption = {
    name: string;
    value: string;
}

export type PortalConversation = {
    sourceQuestionId: string;
    sourceQuestionName: string
    nextQuestionId: string;
    nextQuestionName: string;
    answers: Array<string>;
}

export type ConversationLogic = {
    nextQuestionId: string;
    conditions?: Array<ConversationLogicCondition>;
}

export type ConversationLogicCondition = {
    sourceQuestionId: string;
    answers: Array<string>;
}

export type FormLogic = {
    sourceQuestions?: Array<Question>;
    destinationQuestion?: Array<Question>;
    logic: (question?: Array<Question>, destination?: Array<Question>) => void;
}

export type DataRow = {
    id?: string;
    virtualId?: number;
    isDeleted: boolean;
    readOnly?: boolean;
    columns?: Array<Question> | undefined;
    key: string;
}

// export type TableColumnDefinition = {
//     question?: Question;
//     header?: string;
//     id?: string;
// }

export type PortalTable = {
    key: string;
    columns?: Array<Question>;
    sourceQuestionId?: string;
    isAddVisible?: boolean;
    isRemoveVisible?: boolean;
    addFirstEmptyRow?: boolean;
    maxNumberOfRows?: number;
    rows?: Array<DataRow>;
    addButtonLabel?: string;
    removeButtonLabel?: string;
}

export type PageQuestion = {
    question: Question;
    page?: Page;
    pageNumber: number;
    questionNumber: number;
}

export type ValidationMessage = {
    question: Question;
    message: string;
}

export type Question = {
    id: string;
    order?: number;
    key: string;

    questionType: ControlType;
    hidden?: boolean;
    readOnly?: boolean;
    mandatory?: boolean;

    minValue?: number;
    maxValue?: number;

    minLength?: number;
    maxLength?: number;

    header: HeaderDetails;
    questionHtml: string;
    footer: FooterDetails;
    schema: string;
    answerId?: string;
    answer?: string;
    options?: Array<DropdownOption>;
    isHidden(): boolean;
    tableId?: string;

    isDirty?: boolean;

    validationState?: 'error' | 'warning' | 'success' | 'none';
    errorMessage?: string;
    warningMessage?: string;
}

export enum ControlType {
    Text = 100000000,
    MultilineText = 100000001,
    Dropdown = 100000002,
    DropdownYesNo = 100000013,
    MultiselectDropdown = 100000003,
    WholeNumber = 100000004,
    Money = 100000005,
    Decimal = 100000006,
    Email = 100000007,
    Html = 100000009,
    Checkbox = 100000011,
    Lookup = 100000010,
    Table = 100000008,
    PortalSection = 100000100,
    Date = 100000012,
}

export enum PageType {
    PageWithQuestions = 100000000,
    PageWithTable = 100000001,
    SubmitPage = 100000002,
}

export type PageData = {
    attributes: Array<Attribute>;
}

export type Attribute = {
    name: string;
    value?: string;
    type: ControlType;
}
// export const templateData: Template = {
//     header: {
//         headerHtml: "<b>Application Header</b>",
//     },
//     footer: {
//         isBackVisible: false,
//         isPrintVisible: false,
//     },
//     pages: [
//         {
//             header: {
//                 headerHtml: "<b>Page Header 1</b>",
//             },
//             footer: {
//                 footerHtml: ""
//             },
//             sections: [{
//                 header: {
//                     headerHtml: "<b>Section Header</b>",
//                 },
//                 footer: {
//                     footerHtml: ""
//                 },
//                 questions: [
//                     {
//                         header: {
//                             headerHtml: "<b>Question Header 1</b>",
//                         },
//                         footer: {
//                             footerHtml: ""
//                         },
//                         id: "",
//                         questionHtml: "test 1",
//                         questionType: 1,
//                         schema: "text1",
//                         readOnly: false,
//                         isHidden() { return false; },
//                     },
//                     {
//                         header: {
//                             headerHtml: "<b>Question Header 2</b>",
//                         },
//                         footer: {
//                             footerHtml: ""
//                         },
//                         id: "",
//                         questionHtml: "test 2",
//                         questionType: 2,
//                         schema: "text2",
//                         readOnly: false,
//                         isHidden() { return false; },
//                     }
//                 ]
//             }]
//         },
//         {
//             header: {
//                 headerHtml: "<b>Page Header 2</b>",
//             },
//             footer: {
//                 footerHtml: ""
//             },
//             sections: [{
//                 header: {
//                     headerHtml: "<b>Section Header</b>",
//                 },
//                 footer: {
//                     footerHtml: ""
//                 },
//                 questions: [
//                     {
//                         header: {
//                             headerHtml: "<b>Question Header</b>",
//                         },
//                         footer: {
//                             footerHtml: ""
//                         },
//                         id: "",
//                         questionHtml: "test",
//                         questionType: 1,
//                         schema: "text3",
//                         readOnly: false,
//                         isHidden() { return false; },
//                     }
//                 ]
//             }]
//         }
//     ],
//     currentPage: 0,
// }   