import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export const PageHeader = () => {
    return (
        <>
            <div className="shadow-lg">
                <header className="container mx-auto font-sans py-6">
                    <div className="flex">
                        <div className="flex">
                            <Link to="/">
                                <img className="h-15 w-80" src="https://www.irishtaxrebates.ie/img/itr-logo.svg" alt="Irish Tax Rebates" />
                            </Link>
                        </div>
                        <div className="flex container mx-auto items-center justify-center text-2xl font-normal">
                            My Tax Rebates
                        </div>
                        <div className="w-12">
                            <button type="button" className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="absolute -inset-1.5"></span>
                                <span className="sr-only">View notifications</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                                </svg>
                            </button>
                        </div>
                        <div className="sm:visible w-12">
                            <button type="button" className="relative ml-auto flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                <span className="absolute -inset-1.5"></span>
                                <span className="sr-only">Open user menu</span>
                                {/*<img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />*/}
                            </button>
                        </div>
                    </div>
                </header>
            </div>   
                 
        </>
    );
}