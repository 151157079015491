import { Question } from "../../TemplateData";
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { DynamicFormComponentProps } from "./dynamic-form-component";
import { read } from "fs";

// type DynamicFormDatePickerProps = {
//     question: Question;
//     id: string;
//     updateQuestion(questionId: string, answer: string): void;
// };

export const DynamicFormDatePicker = (props: DynamicFormComponentProps) => {

    const formatDate = (date?: Date): string => {
        if (!date) return '';
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const onChange = (date: Date | null | undefined): any => {
        console.log(date);
        props.updateQuestion(props.question.id, date?.toISOString() ?? "", props.sourceQuestionId, props.rowVirtualId);
    }

    let date: Date | null = props.question.answer != null ? new Date(props.question.answer) : null;

    return (
        <DatePicker key={props.question.id} formatDate={formatDate} size={props.size} style={{ width: "100%" }} onSelectDate={onChange} value={date} disabled={props.question.readOnly} />
    );
}