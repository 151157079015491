import { BrandVariants, createDarkTheme, createLightTheme, Theme } from "@fluentui/react-components";

const itr: BrandVariants = {
    10: "#070200",
    20: "#271006",
    30: "#43170C",
    40: "#5A1B0F",
    50: "#721E10",
    60: "#8A2012",
    70: "#A42112",
    80: "#BE2113",
    90: "#D72717",
    100: "#DF472E",
    110: "#E76045",
    120: "#EE775B",
    130: "#F48D73",
    140: "#F9A18B",
    150: "#FDB6A3",
    160: "#FFCBBC"
};

export const itrLightTheme: Theme = {
    ...createLightTheme(itr),
};

export const itrDarkTheme: Theme = {
    ...createDarkTheme(itr),
};

itrDarkTheme.colorBrandForeground1 = itr[110];
itrDarkTheme.colorBrandForeground2 = itr[120];