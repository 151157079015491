import React, { Component, useEffect, useState } from 'react';
import { DynamicFormContent } from './dynamic-form-content';
import { Attribute, ControlType, DataRow, DropdownOption, FormLogic, Page, PageData, PageQuestion, PageType, PortalConversation, PortalTable, Question, Section, Template } from '../../TemplateData';
import { Link } from 'react-router-dom';
import { Avatar, Button, Divider, MessageBar } from "@fluentui/react-components";
import { DynamicFormLogic, TaxPositionLogic } from './dynamic-form-logic';
import FormHelpers from '../helpers/form-helpers';
import { copyFileSync } from 'fs';
import { CalendarMonthRegular, ArrowRightFilled, ArrowRightRegular, AttachArrowRightRegular, ArrowCircleRightFilled, ArrowCircleRightRegular, ArrowLeftFilled } from "@fluentui/react-icons";

export type DynamicFormComponentProps = {
    question: Question;
    id: string;
    sourceQuestionId?: string;
    rowVirtualId?: number;
    size?: 'small' | 'medium' | 'large';
    updateQuestion(questionId: string, answer: string, sourceQuestionId?: string, rowVirtualId?: number): void;
}

export const DynamicFormComponent = () => {
    var [currentPage, setCurrentPage] = useState(0);
    var [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<Template>();

    let dynamicFormLogic: DynamicFormLogic = new TaxPositionLogic();

    const getQuestion = (templateQuestion: any, questionIndex: number): Question => {
        let question: Question = {
            id: templateQuestion.questionId,
            order: templateQuestion.order,
            key: FormHelpers.generateId().toString(),
            header: {
                headerHtml: templateQuestion.header,
            },
            footer: {
                footerHtml: templateQuestion.footer,
            },
            questionHtml: templateQuestion.question,
            questionType: templateQuestion.questionType,
            schema: templateQuestion.schemaName,
            hidden: templateQuestion.hidden,
            readOnly: templateQuestion.readOnly,
            mandatory: templateQuestion.mandatory,
            minValue: templateQuestion.minNumber,
            maxValue: templateQuestion.maxNumber,
            minLength: templateQuestion.minLength,
            maxLength: templateQuestion.maxLength,
            //answer: "",
            isHidden() { return false; },
        };

        switch (question.questionType) {
            case ControlType.Dropdown:
            case ControlType.MultiselectDropdown:
            case ControlType.DropdownYesNo:
                question.options = templateQuestion.options.map((o: any) => {
                    let option: DropdownOption = {
                        name: o.name,
                        value: o.value,
                    }
                    return option;
                })
                break;
            case ControlType.Table:
                question.tableId = templateQuestion.TableId;
                break;
        }
        return question;
    }

    const getData = () => {
        if (isLoading == true) {

            fetch("taxposition").then((response) => {
                return response.json();
            }).then((data) => {
                let questions: Array<PageQuestion> = new Array<PageQuestion>();

                let template: Template = {
                    header: {
                        headerHtml: data.header ?? "",
                    },
                    footer: {
                        isBackVisible: false,
                        isExportVisible: false,
                        isSaveVisible: false,
                        isPrintVisible: false,
                    },
                    pages: data.pages.map((templatePage: any, pageIndex: number) => {
                        let page: Page = {
                            header: {
                                headerHtml: templatePage.header,
                            },
                            footer: {
                                footerHtml: templatePage.footer,
                            },
                            key: FormHelpers.generateId().toString(),
                            pageType: templatePage.pageType,
                            sections: templatePage.sections.map((templateSection: any) => {
                                let section: Section = {
                                    header: {
                                        headerHtml: templateSection.header,
                                    },
                                    footer: {
                                        footerHtml: templateSection.footer,
                                    },
                                    key: FormHelpers.generateId().toString(),
                                    questions: templateSection.questions.map((templateQuestion: any, questionIndex: number) => {
                                        let question: Question = getQuestion(templateQuestion, questionIndex);
                                        questions.push({
                                            pageNumber: pageIndex,
                                            questionNumber: questionIndex,
                                            question: question,
                                        });

                                        return question;
                                    }),
                                }
                                return section;
                            }),
                        };
                        return page;
                    }),
                    conversation: data.conversation.map((templateConversation: any) => {
                        let conversation: PortalConversation = {
                            nextQuestionId: templateConversation.nextQuestionId,
                            nextQuestionName: templateConversation.nextQuestionName,
                            sourceQuestionId: templateConversation.sourceQuestionId,
                            sourceQuestionName: templateConversation.sourceQuestionName,
                            answers: templateConversation.answer,
                        }
                        return conversation;
                    }),
                    tables: data.tables.map((templateTable: any) => {
                        let table: PortalTable = {
                            sourceQuestionId: templateTable.sourceQuestionId,
                            columns: templateTable.columns.map((templateColumn: any, index: number) => {
                                let column: Question = getQuestion(templateColumn, index);
                                return column;
                            }),
                            isAddVisible: true,
                            isRemoveVisible: true,
                            maxNumberOfRows: 10,
                            key: FormHelpers.generateId().toString(),
                            rows: [],
                        };

                        let addFirstEmptyRow: boolean = true;
                        if (addFirstEmptyRow) {
                            let dataRow: DataRow = {
                                columns: table?.columns?.map((column: Question) => {
                                    let copiedColumn: Question = { ...column };
                                    copiedColumn.key = FormHelpers.generateId().toString();
                                    return copiedColumn;
                                    //return { ...column };
                                }),
                                virtualId: Date.now() + Math.random(),
                                isDeleted: false,
                                key: FormHelpers.generateId().toString(),
                            }
                            table.rows?.push(dataRow);
                        }
                        return table;
                    }),
                    currentPage: 0,
                    questions: questions,
                }
                template.pageHistory = new Array<number>();
                template.pageHistory.push(template.currentPage);

                console.log(template);

                dynamicFormLogic.registerLogic(template);

                setData(template);
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const updateQuestion = (questionId: string, answer: string, sourceQuestionId?: string, rowVirtualId?: number): void => {

        if (sourceQuestionId != undefined && rowVirtualId != undefined) {
            //find table for which the source question belongs
            let table: PortalTable | undefined = data?.tables?.find((table: PortalTable) => {
                return table.sourceQuestionId == sourceQuestionId;
            });

            if (table != undefined) {
                //find source question
                let tableQuestion: PageQuestion | undefined = data?.questions?.find((pageQuestion: PageQuestion) => {
                    return pageQuestion.question.id == sourceQuestionId;
                });

                //find row
                let row: DataRow | undefined = table.rows?.find((row: DataRow) => {
                    return row.virtualId == rowVirtualId;
                });

                if (row != undefined) {
                    var question: Question | undefined = row.columns?.find((column: Question) => {
                        return column.id == questionId;
                    });
                    if (question != undefined) {
                        question.answer = answer;
                        question.isDirty = true;

                        if (tableQuestion != undefined) {
                            let tableAnswer = JSON.stringify(table.rows);
                            tableQuestion.question.answer = tableAnswer;
                        }

                        if (data != undefined) {
                            executeCustomLogic(questionId);
                            setData({ ...data });
                            return;
                        }
                    }
                }
            }
        }

        data?.pages.forEach((page: Page) => {
            page?.sections.forEach((section: Section) => {
                var q = section?.questions.find((question: Question) => {
                    return question.id == questionId;
                });

                if (q != null) {
                    q.answer = answer;
                    q.isDirty = true;
                    executeCustomLogic(questionId);
                    setData({ ...data });
                    return;
                }
            })
        });
    };

    const executeCustomLogic = (questionId: string) => {
        //custom logic
        if (data?.logics != null) {
            let formLogic = data.logics?.filter((logic: FormLogic) => {
                return logic.sourceQuestions != undefined && logic.sourceQuestions.find((question) => { return question.id == questionId }) != undefined;
            });

            if (formLogic.length == 0)
                return;

            formLogic.forEach((formLogic) => {
                formLogic.logic(formLogic.sourceQuestions, formLogic.destinationQuestion);
            });
        }
    }

    const saveData = (currentPage: number): boolean => {
        let questions = data?.questions?.filter((pageQuestion: PageQuestion) => {
            return pageQuestion.pageNumber == currentPage && pageQuestion.question.isDirty === true;
        });

        let attributes: Array<Attribute> | undefined = questions?.map((pageQuestion) => {
            let attribute: Attribute = {
                name: pageQuestion.question.schema,
                value: pageQuestion.question.answer,
                type: pageQuestion.question.questionType,
            }
            return attribute;
        });

        if (attributes != undefined && attributes.length > 0) {
            let pageData: PageData = {
                attributes: attributes,
            };
            console.log(pageData);

            questions?.forEach((pageQuestion) => {
                pageQuestion.question.isDirty = false;
            });

            if (data != undefined)
                setData({ ...data });

        }

        return true;
    }

    const onNextClick = () => {
        if (data != undefined) {
            //clear error messages
            data.questions?.forEach((pageQuestion: PageQuestion) => { pageQuestion.question.validationState = undefined });
            let issues = dynamicFormLogic.validatePage(data, currentPage);
            //console.log(issues);
            data.validationMessages = issues;
            if (issues == undefined || (issues != undefined && issues.length == 0)) {

                let isSaved = saveData(currentPage);
                if (isSaved) {
                    currentPage = dynamicFormLogic.getNextPage(data, currentPage);
                    if (data?.pageHistory != undefined) {
                        var previousPage = data?.pageHistory.length > 0 ? data?.pageHistory[data?.pageHistory.length - 1] : currentPage;
                        if (previousPage != currentPage)
                            data?.pageHistory.push(currentPage);
                    }
                    setCurrentPage(currentPage);
                }
            }
            else {
                setData({ ...data });
            }
        }
        window.scrollTo(0, 0);
    }

    const onPreviousClick = () => {
        if (data?.pageHistory != undefined) {
            data?.pageHistory.pop();
            data.validationMessages = undefined;
            currentPage = data?.pageHistory[data?.pageHistory?.length - 1 ?? 0];
            setCurrentPage(currentPage);
            window.scrollTo(0, 0);
        }
    }

    const onSubmitClick = () => {
        if (data != undefined) {
            //clear error messages
            data.questions?.forEach((pageQuestion: PageQuestion) => { pageQuestion.question.validationState = undefined });
            let issues = dynamicFormLogic.validatePage(data, currentPage);
            //console.log(issues);
            data.validationMessages = issues;
            if (issues == undefined || (issues != undefined && issues.length == 0)) {
                //save tables
            }
            else {
                setData({ ...data });
            }
            window.scrollTo(0, 0);
        }
    }

    if (isLoading) {
        return (<>
            <div className="h-72">
            </div>
            <div className="text-center">
                <div role="status" className="align-bottom h-48">
                    <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>);
    }

    return (<>
        {data != null &&
            <div className="mx-auto max-w-screen-lg font-seans shadow-xl my-20 rounded-md">
                <div className="bg-[#d52114] py-3 text-white text-4xl text-center font-light rounded-t-md shadow-xl ms-fontSize-68">
                    {(data.header.headerHtml)}
                </div>

                <DynamicFormContent pages={data.pages} tables={data.tables} currentPage={currentPage} updateQuestion={updateQuestion} validationMessages={data.validationMessages} ></DynamicFormContent>

                <Divider></Divider>
                {/* <div className="p-5">test</div>
                <div className="m-4 bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
  <p className="font-bold">Be Warned</p>
  <p>Something not ideal might be happening.</p>
</div> */}

                <div className="grid grid-cols-2 p-5 bg-gradient-to-r from-red-100 to-red-200">
                    <div>
                        {data?.footer?.isBackVisible &&
                            <Link to="/" className="mx-1 bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-4 rounded">Back</Link>
                        }
                        {data?.footer?.isSaveVisible &&
                            <Link to="#" className="mx-1 bg-red-500 hover:bg-red-700 text-white font-normal py-2 px-4 rounded">Save</Link>
                        }
                        {data?.footer?.isPrintVisible &&
                            <Link to="#" className="mx-1 bg-gray-500 hover:bg-gray-700 text-white font-normal py-2 px-4 rounded">Print</Link>
                        }
                        {data?.footer?.isExportVisible &&
                            <Link to="#" className="mx-1 bg-gray-500 hover:bg-gray-700 text-white font-normal py-2 px-4 rounded">Export</Link>
                        }
                    </div>
                    <div className="text-right">
                        {currentPage != 0 &&
                            // <Link to="#" className="mx-1 bg-gray-500 hover:bg-gray-700 text-white font-normal py-2 px-4 rounded" onClick={onPreviousClick} >Previous</Link>
                            <Button size="large" icon={<ArrowLeftFilled className="w-2/3" />} iconPosition="before" onClick={onPreviousClick}>Previous</Button>
                        }
                        {data.pages[currentPage].pageType != PageType.SubmitPage &&
                            // <Link to="#" className="mx-1 bg-[#d52114] hover:bg-red-700 text-white font-normal py-2 px-4 rounded" onClick={onNextClick} >Next</Link>
                            <Button appearance="primary" size="large" icon={<ArrowRightFilled className="w-2/3" />} iconPosition="after" onClick={onNextClick} style={{ marginLeft: 10 }} >Next</Button>
                        }
                        {data.pages[currentPage].pageType == PageType.SubmitPage &&
                            <Button appearance="primary" size="large" icon={<ArrowRightFilled className="w-2/3" />} iconPosition="after" onClick={onSubmitClick} style={{ marginLeft: 10 }}>Submit</Button>
                        }
                    </div>
                </div>
            </div>}
    </>);
}