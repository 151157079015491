import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

export const TaxHistory = () => {
    let title = "My Tax History";
    let tableData = [{
        receivedOn: "17/06/2023",
        refund: "€300",
        fee: "€20",
        rebatePayment: "€280",
    }, {
        receivedOn: "15/05/2022",
        refund: "€1300",
        fee: "€200",
        rebatePayment: "€1100",
    }, {
        receivedOn: "28/08/2021",
        refund: "€200",
        fee: "€15",
        rebatePayment: "€185",
    }];
    return (
        <Container className='Members-list'>
            <h2>{title}</h2>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Received On</th>
                        <th>Refund</th>
                        <th>Fee</th>
                        <th>Rebate Payment</th>
                    </tr>
                </thead>
                <tbody> 
                    {tableData.map((row) => (
                        <tr>
                            <td>{row.receivedOn}</td>
                            <td>{row.refund}</td>
                            <td>{row.fee}</td>
                            <td>{row.rebatePayment}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}