import { DataRow, FormLogic, PortalTable, Question, Template } from "../../../TemplateData";
import TableHelpers from "../../helpers/table-helpers";

export class DependentRelatives {
    template: Template;
    constructor(formTemplate: Template) {
        this.template = formTemplate;
    }

    registerLogic() {
        let table77: PortalTable | undefined = this.template?.tables?.find((table: PortalTable) => {
            return table.sourceQuestionId == "336b3d1e-7275-ee11-8178-000d3add60ec";
        });

        if (table77 != undefined) {
            table77.addButtonLabel = "Add another relative";
        }
    }
}