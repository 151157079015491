import { Button, Dropdown, Field, Input, SpinButton, Textarea, Option, } from '@fluentui/react-components';
import { ControlType, Question } from "../../TemplateData";
import { OptionOnSelectData } from '@fluentui/react-combobox';
import { DynamicFormComponentProps } from './dynamic-form-component';

// type DynamicFormMultiDropdownProps = {
//     question: Question;
//     id: string;
//     updateQuestion(questionId: string, answer: string): void;
// };

export const DynamicFormMultiDropdown = (props: DynamicFormComponentProps) => {

    const onChange = (event: any, data: OptionOnSelectData): any => {
        let value: string | undefined;
        if (props.question.questionType == ControlType.MultiselectDropdown) {
            if (data.selectedOptions.length == 0)
                value = "";
            else
                value = data.selectedOptions.join(";#");
        }
        else {
            value = data.optionValue ?? "";
        }

        props.updateQuestion(props.question.id, value, props.sourceQuestionId, props.rowVirtualId);
    }

    if (props?.question?.answer != undefined)
        console.log(props?.question?.answer);

    let selectedOptions = props?.question?.answer?.split(";#");

    let options = props.question?.options?.filter((option) => {
        return selectedOptions != undefined && selectedOptions?.indexOf(option.value) > -1;
    }).map((option) => {
        return option.name
    });

    const defaultValue: string | undefined = options?.join(",");
    const size = props.size ?? "medium";

    return (
        <Dropdown key={props.question.id} size={size} style={{ width: "100%" }} defaultValue={defaultValue} defaultSelectedOptions={selectedOptions} onOptionSelect={onChange} 
            multiselect={true} disabled={props.question.readOnly}>
            {props.question?.options?.map((option) => (
                <Option key={option.name + "_" + option.value} value={option.value} defaultChecked={true} >
                    {option.name}
                </Option>
            ))}
        </Dropdown>
    );
}